import React from "react"
import Layout from "../components/layout-static"
import Seo from "../components/seo"
import { Link } from "gatsby"

const Page = () => {
  const metadata = {
    title: "Probate and Estate Administration",
    description:
      "Need legal advice to maximize estate value in probate? Bartlett & Grippe guide executors and administrators to prevent personal liability for losses. Contact us for help.",
    heroH1: "Probate and Estate Administration",
    heroH2:
      "Bartlett & Grippe will help you put your estate and probate affairs in order.",
    heroImage: "hero-probate.jpg",
    heroImageAlt: "The word probate written out in Scrabble letters",
  }
  return (
    <Layout metadata={metadata}>
      <Seo title={metadata.title} description={metadata.description} />
      <div
        id="content"
        className="flex flex-wrap container mx-auto px-4 xl:px-0"
      >
        <div className="w-full pt-8 xl:pt-0">
          <div className="mb-16">
            <h2 className="uppercase">
              Our experienced attorneys will assist executors and administrators
            </h2>
            <p className="mb-8">
              To maximize the value of an estate in probate or another
              administration process, representatives may find it helpful to
              contact an established estate planning law firm. At Bartlett &
              Grippe LLC, our attorneys advise clients serving as executors or
              administrators on how to comply with all aspects of estate
              administration accurately and completely. If you’ve been appointed
              to either position, you can benefit from sound legal guidance so
              you are not held personally liable for losses in the value of
              estate assets due to errors or misconduct. We guide individuals in
              the Cheshire area and throughout Connecticut through probate and
              estate administration with confidence and efficiency.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              We are trained to advise clients during every step of probate
            </h2>
            <p className="mb-8">
              Probate can refer to the court process of validating a will so an
              executor can implement the decedent’s wishes or to the oversight
              the court provides for an estate administrator who settles an
              intestate estate — one in which the decedent left no will.
            </p>
            <p className="mb-8">
              Most executors are not familiar with the relevant laws, and some
              are even surprised to learn the decedent’s will named them as the
              responsible party. To complete the process of settling an estate,
              our lawyers may help executors with the following tasks:
            </p>
            <ul className="list-disc list-outside ml-6 mb-8 space-y-2">
              <li>
                Getting court approval as estate administrator and posting bond
              </li>
              <li>Filing the will with the appropriate probate court</li>
              <li>Locating beneficiaries</li>
              <li>Finding and assembling assets</li>
              <li>Transferring assets from the deceased to the estate</li>
              <li>
                Hiring valuation, genealogy or other experts, when appropriate
              </li>
              <li>Paying creditors and claimants</li>
              <li>Collecting amounts owed to the estate</li>
              <li>
                Paying current and delinquent taxes as well as estate taxes
              </li>
              <li>Distributing property to beneficiaries</li>
            </ul>
            <p className="mb-8">
              Estate representatives can easily make mistakes due to
              inexperience, stress and hasty decisions. The lawyers at Bartlett
              & Grippe, LLC provide clients with reliable, detailed guidance.
            </p>
            <p className="mb-8">
              Even in seemingly straightforward probate and estate
              administration matters, disputes among beneficiaries or between
              beneficiaries and the executor can arise. When these conflicts
              surface, you can count on our sound counsel and professionalism in
              negotiations or in court.
            </p>
          </div>
          <div className="mb-16">
            <h2 className="uppercase">
              We help our clients become executors and administrators
            </h2>
            <p className="mb-8">
              Distributing a decedent’s property cannot take place without an
              executor or administrator managing and overseeing the process.
              When a person does not leave behind a will or establish another
              means of wealth transfer, the most willing and capable relative
              usually seeks court approval to act as the personal representative
              of the estate. We can help qualified individuals obtain the status
              of estate executor or administrator. At Bartlett & Grippe, LLC,
              our goal is for you to settle your loved one’s estate as
              efficiently, quickly and easily as possible.
            </p>
            <p className="mb-8">
              Probate and estate administration issues can feel overwhelming for
              people unfamiliar with this area of law. Our attorneys can help to
              ease the burden.
            </p>
            <Link to="/contact-us/">
              <button className="mb-8 mx-auto cta-button-green">
                Contact us today for a free confidential consultation
              </button>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}

export default Page
